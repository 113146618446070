import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Link } from '@chakra-ui/react'
import LinkReactRouter from './link'
import Slider from 'react-slick'

const Hero = ({ ...props }) => {
  const urlChristmas = '/products/christmas-in-a-pie'
  const altChristmas =
    'Flash Sale - Ends Tonight! Christmas in a Pie: British Turkey with all the trimmings in a port and cranberry reduction. Comfort and joy this festive season.'
  const urlLamb = '/products/spring-lamb-and-mint-pie'
  const altLamb =
    'Limited edition Spring Lamb and Mint Pie available for a short time only'
  const altFlashAug =
    'Flash Sale - One Day Only! Ends Tonight: Chicken and Spanish Chorizo Pie and Pulled Pork in Sticky BBQ Sauce Pie for £3.99 Each - Limited Editions - While Stocks Last'
  const urlFlashAug = 'https://mudfoods.com/collections/flash-sale-august'
  const altFish = 'Limited edition Fish Pie only 250 available'
  const urlFish = 'https://mudfoods.com/products/luxury-fish-pie-470g'
  const altChorizo = 'Limited edition Chicken and Spanish Chorizo Pie'
  const urlChorizo = '/products/chicken-and-spanish-chorizo-pie'
  const altSummer = 'Relax This Summer with Our Scrumptious Pies and Squiches™!'

  const urlPulled = '/products/pulled-pork-in-sticky-barbecue-sauce'
  const altPulled = 'Limited edition Sticky BBQ pork pie'
  const urlSummer = '/collections/savoury-pies-and-squiches'

  const urlhb = '/products/hearty-beef-and-potato-pie'
  const hbtitle = `Thank you Mud Foods for my delivery of meat and potato pies today. We had two for tea and they were beautiful - the pastry was delicious and the filling was absolutely full to the brim. Definitely be ordering again."r). Discount applied automatically. Free delivery on orders of £45 (and over),`

  const clickedBannerEvent = () => {
    if (
      process.env.NODE_ENV === `production` &&
      typeof _learnq.push === `function` // eslint-disable-line no-undef
    ) {
      // prettier-ignore
      _learnq.push([// eslint-disable-line no-undef
        
        'track',
        'Clicked Banner',
        {
          SourceURL: 'https://mudfoods.com/',
          DestinationURL: 'https://mudfoods.com' + urlhb,
          BannerTitle: hbtitle,
        },
      ])
    }
  }

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
  }
  const settingsMobile = {
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 0,
        settings: 'unslick',
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'inline-block !important',
          '@media screen and (min-width: 640px)': {
            display: 'none !important',
          },
        }}
      >
        <Slider {...settingsMobile}>
          <Link
            to={urlChristmas}
            key="flashChristmas"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/christmas2024/mobile/02.jpg"
              alt={altChristmas}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          {/* <Link
            to={urlFlashAug}
            key="flashFlashAug"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/flash-aug/1/mobile/01.jpg"
              alt={altFlashAug}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          {/*  <Link
            to={urlFish}
            key="flashFish"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/fish/mobile/01.jpg"
              alt={altFish}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlChorizo}
            key="flashChorizo"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/chorizo/mobile/01.jpg"
              alt={altChorizo}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlPulled}
            key="flashPulled"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pulled/mobile/01.jpg"
              alt={altPulled}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlMatch}
            key="flashMatch"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/match/mobile/01.jpg"
              alt={altMatch}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlSummer}
            key="summerday2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/summer-2024/mobile/01.jpg"
              alt={altSummer}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          {/* <Link
            to={urlBPA2024}
            key="flashBPW2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpa2024/mobile/01.jpg"
              alt={altBPA2024}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlTunworth}
            key="flashBPWTun2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/mobile/01.jpg"
              alt={altTunworth}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <Link
            to={urlBFG}
            key="flashBPWBFG2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/mobile/02.jpg"
              alt={altBFG}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlPasty}
            key="pasty2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pasty/mobile/01.jpg"
              alt={altPasty}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlRugby}
            key="Rugby2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/rugby/mobile/01.jpg"
              alt={altRugby}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
        </Slider>
      </Container>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'none !important',
          '@media screen and (min-width: 640px)': {
            display: 'inline-block !important',
          },
        }}
      >
        <Slider {...settingsMain}>
          <Link
            to={urlChristmas}
            key="ChristmasFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/christmas2024/desktop/02.jpg"
              alt={altChristmas}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          {/* <Link
            to={urlFlashAug}
            key="FlashAugFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/flash-aug/1/desktop/01.jpg"
              alt={altFlashAug}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          {/* <Link
            to={urlFish}
            key="FishFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/fish/desktop/01.jpg"
              alt={altFish}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlChorizo}
            key="ChorizoFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/chorizo/desktop/01.jpg"
              alt={altChorizo}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlPulled}
            key="PulledFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pulled/desktop/01.jpg"
              alt={altPulled}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlMatch}
            key="MatchFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/match/desktop/01.jpg"
              alt={altMatch}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlSummer}
            key="SummerDay1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/summer-2024/desktop/01.jpg"
              alt={altSummer}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          {/* <Link
            to={urlBPA2024}
            key="BPW1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpa2024/desktop/01.jpg"
              alt={altBPA2024}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
          {/* <Link
            to={urlTunworth}
            key="BPWTun1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/desktop/01.jpg"
              alt={altTunworth}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link>
          <Link
            to={urlBFG}
            key="BPWBFG1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/desktop/02.jpg"
              alt={altBFG}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlRugby}
            key="Rugby1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/rugby/desktop/01.jpg"
              alt={altRugby}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
        </Slider>
      </Container>
    </>
  )
}

export default Hero
